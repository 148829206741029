import React, { useState, useEffect } from "react";
import "./homepage.scss";

function Homepage() {
  const [p1pass, sp1pass] = useState("");
  const [p2pass, sp2pass] = useState("");
  const [p3pass, sp3pass] = useState("");
  const [p4pass, sp4pass] = useState("");
  const [p5pass, sp5pass] = useState("");
  const [p6pass, sp6pass] = useState("");
  const [p7pass, sp7pass] = useState("");
  const [date, setDate] = useState(new Date());

  const handleKeyDown = (e) => {
    e.target.style = { color: "white" };
  };

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <div className="homepage">
      <div className="title">COME BACK WHEN THE DRUMMERS DRUM</div>
      {date.getHours() === 0 || date.getHours() === 12 ? (
        <div className="ladies">NOW WAIT TIL THE MAIDS START MILKING</div>
      ) : null}
      <div className="password-label">ENTER PASSWORD</div>
      <div className="login-section">
        <input
          type="text"
          className="password"
          value={p1pass}
          onChange={(e) => sp1pass(e.target.value)}
        />
      </div>
      {date.getMinutes() === 8 ? (
        <div className="clue">
          REMEMBER REMEMEBER THE 26TH OF DECEMBER. IT ALWAYS STARTS AT THE
          BEGINNING.
        </div>
      ) : null}
      {/* <div className="alphabet">
        {[
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
        ].map((letter) => {
          return (
            <div
              className="letter"
              onClick={(e) => handleKeyDown(e)}
              value={letter}
            >
              {letter}
            </div>
          );
        })}
      </div> */}
      <div className="hidden-text"></div>
      {p1pass.toLowerCase() === "freckles" ? (
        <div className="puzzle2">
          <div className="puzzle2-text">WAKE UP. YOU'RE DREAMING</div>
          <div className="words">
            <div className="word">
              <span className="sldkf">X</span>
              <span className="daksld">X</span>
              <span className="lllkdd">X</span>
              <span className="pekadf">X</span>
            </div>
            <div className="word">
              <span className="daksld">X</span>
              <span className="wefds">X</span>
              <span className="pekadf">X</span>
            </div>
            <div className="word">
              <span className="f">X</span>
              <span className="i">X</span>
              <span className="sldkf">X</span>
              <span className="wefds">X</span>
              <span className="pekadf">X</span>
              <span className="r">X</span>
              <span className="mdsdjfd">X</span>
            </div>
            <div className="word">
              <span className="p">X</span>
              <span className="r">X</span>
              <span className="pekadf">X</span>
              <span className="mdsdjfd">X</span>
              <span className="i">X</span>
              <span className="d">X</span>
              <span className="pekadf">X</span>
              <span className="sldkf">X</span>
              <span className="t">X</span>
            </div>
          </div>
          <div className="words">
            <input
              type="text"
              className="answer"
              value={p2pass}
              onChange={(e) => sp2pass(e.target.value)}
            />
            <input
              type="text"
              className="answer"
              value={p3pass}
              onChange={(e) => sp3pass(e.target.value)}
            />
            <input
              type="text"
              className="answer"
              value={p4pass}
              onChange={(e) => sp4pass(e.target.value)}
            />
            <input
              type="text"
              className="answer"
              value={p5pass}
              onChange={(e) => sp5pass(e.target.value)}
            />
          </div>
        </div>
      ) : null}
      {(p2pass.toLowerCase() === "yoshy" || p2pass.toLowerCase() === "josh") &&
      (p3pass.toLowerCase() === "3" ||
        p3pass.toLowerCase() === "23" ||
        p3pass.toLowerCase() == "30") &&
      (p4pass.toLowerCase() === "30" || p4pass.toLowerCase() === "3") &&
      (p5pass.toLowerCase().includes("kamala") ||
        p5pass.toLowerCase().includes("biden")) ? (
        <div className="puzzle3">
          <div className="words">
            <div className="word">
              <span className="daksld">X</span>
              <span className="cxkcm">X</span>
              <span className="c">X</span>
              <span className="d">X</span>
              <span className="f">X</span>
              <span className="wefds">X</span>
              <span className="i">X</span>
              <span className="k">X</span>
              <span className="l">X</span>
              <span className="lllkdd">X</span>
              <span className="p">X</span>
              <span className="q">X</span>
              <span className="r">X</span>
              <span className="t">X</span>
              <span className="v">X</span>
              <span className="w">X</span>
              <span className="x">X</span>
              <span className="y">X</span>
              <span className="z">X</span>
            </div>
          </div>
          <div className="words">
            <input
              type="text"
              className="answer"
              value={p6pass}
              onChange={(e) => sp6pass(e.target.value)}
            />
            <input
              type="text"
              className="answer"
              value={p7pass}
              onChange={(e) => sp7pass(e.target.value)}
            />
          </div>
          {(p6pass.toLowerCase() === "josh" ||
            p6pass.toLowerCase() === "euni") &&
          (p7pass.toLowerCase() === "euni" ||
            p7pass.toLowerCase() === "josh") ? (
            <div className="puzzle4">
              <div className="words">
                <div className="word">
                  <span className="r">X</span>
                  <span className="o">X</span>
                  <span className="k">X</span>
                  <span className="u">X</span>
                </div>
                <div className="word">
                  <span className="pekadf">X</span>
                  <span className="i">X</span>
                  <span className="sldkf">X</span>
                  <span className="mdsdjfd">X</span>
                </div>
                <div className="word">
                  <span className="d">X</span>
                  <span className="pekadf">X</span>
                  <span className="u">X</span>
                  <span className="x">X</span>
                </div>
                <div className="word">
                  <span className="sldkf">X</span>
                  <span className="daksld">X</span>
                  <span className="v">X</span>
                  <span className="daksld ">X</span>
                </div>
                <div className="word">
                  <span className="t">X</span>
                  <span className="daksld">X</span>
                  <span className="t">X</span>
                  <span className="u">X</span>
                </div>
                <div className="word">
                  <span className="mdsdjfd">X</span>
                  <span className="pekadf">X</span>
                  <span className="lllkdd">X</span>
                </div>
                <div className="word">
                  <span className="k">X</span>
                  <span className="o">X</span>
                  <span className="l">X</span>
                  <span className="lllkdd">X</span>
                  <span className="e">X</span>
                </div>
                <div className="word">
                  <span className="sldkf">X</span>
                  <span className="o">X</span>
                  <span className="v">X</span>
                  <span className="pekadf">X</span>
                </div>
                <div className="word">
                  <span className="mdsdjfd">X</span>
                  <span className="pekadf">X</span>
                  <span className="x">X</span>
                </div>
                <div className="word">
                  <span className="sldkf">X</span>
                  <span className="o">X</span>
                  <span className="l">X</span>
                  <span className="l">X</span>
                </div>
              </div>
              <div className="mosh">Moshi Moshi</div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default Homepage;
